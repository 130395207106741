import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import traductionEN from "./en.json";
import traductionFR from "./fr.json";

const resources = {
	en: { translation: traductionEN },
	fr: { translation: traductionFR },
};

i18n
	.use(initReactI18next) // Passe i18next à react-i18next
	.use(LanguageDetector);
	// Besoin pour mettre à jour {t, i18n} quand l'utilisateur va de la tab vide à
	// la page d'accueil en parcourant l'historique de l'explorateur web.

if(!i18n.isInitialized) {
	i18n.init({
		detection: {
			order: ["path", "navigator"],
			lookupFromPathIndex: 0,
		},

		fallbackLng: "en",
		supportedLngs: ["en", "fr"],

		resources,

		keySeparator: ".",	// Pour les traductions imbriquées.

		interpolation: {
			escapeValue: false, // Sécurité vis-à-vis des XSS.
		},
	});
}

export default i18n;
