import { useEffect, useState } from "react";
import Theme from "./Theme";
import getDocumentHtml from "../dom/getDocumentHtml";

function userDefaultTheme() {
	return matchMedia("(prefers-color-scheme: light)").matches? Theme.Light : Theme.Dark;
}

export default function useTheme() : [Theme, (newTheme: Theme) => void] {
	const [theme, setTheme] = useState<Theme>(userDefaultTheme());

	useEffect(() => {
		// Au chargement de l'application, le style par défaut qui est
		// défini afin d'éviter les flashs lors du reload est supprimé
		// afin de laisser les variables prendre le pas.
		// Utiliser !important dans CSS fonctionnerait pour la priorité
		// mais ça fait des flashes avec une vision intermittente de la
		// page en cours de chargement, ce qui est pire.
		getDocumentHtml().style.backgroundColor = "";
	});

	useEffect(() => {
		const htmlClass = getDocumentHtml().classList;

		if(theme === Theme.Light) {
			htmlClass.add("is-light");
		}
		else {
			htmlClass.remove("is-light");
		}
	}, [theme]);

	useEffect(() => {
		matchMedia("(prefers-color-scheme: light)").addEventListener(
			"change",
			(ev: MediaQueryListEvent) => {
				setTheme(ev.matches? Theme.Light : Theme.Dark);
			}
		);
	});

	return [theme, setTheme];
}
